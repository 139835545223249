<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div>
      <span>
        <b>Choose data</b>
      </span>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="choose_table.data"
        @search="handleSearch"
        @change-page="handleChangePage"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.choose_table.start }} - {{ this.choose_table.end }} of
                {{ this.choose_table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in this.choose_table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th>Item Name</vs-th>
          <vs-th>Unit Name</vs-th>
          <vs-th>SKU Code</vs-th>
          <vs-th>Qty</vs-th>
          <vs-th>Expired date</vs-th>
          <vs-th>Batch</vs-th>
          <vs-th>Serial</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].item_name">{{
              data[indextr].item_name
            }}</vs-td>
            <vs-td :data="data[indextr].unit_name">{{
              data[indextr].unit_name
            }}</vs-td>
            <vs-td :data="data[indextr].sku_code">{{
              data[indextr].sku_code
            }}</vs-td>
            <vs-td>
              <vs-input type="number" v-model="data[indextr].qty" :min="1" />
            </vs-td>
            <vs-td>
              <vs-input type="date" v-model="data[indextr].expired_date" />
            </vs-td>
            <vs-td>
              <vs-input type="text" v-model="data[indextr].batch" />
            </vs-td>
            <vs-td>
              <vs-input type="text" v-model="data[indextr].serial" />
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon
                title="add"
                icon="PlusIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
                @click="handleAdd(data[indextr])"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="choose_table.totalPage"
        v-model="setPage"
      />
    </div>

    <!-- <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    filter_data: {
      type: Object,
      required: true,
    },
    table_parent: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      submitDatas: [],
      choose_table: this.tableDefaultState(),
      selected_table: this.selectedTableDefaultState(),
    };
  },
  methods: {
    handleDeleteRecord(id) {
      this.selected_table.data = this.selected_table.data.filter(
        (item) => item.id !== id
      );
    },
    setStartEnd() {
      let valStart =
        this.choose_table.length * this.choose_table.page -
        this.choose_table.length +
        1;
      if (valStart > this.choose_table.total) {
        valStart = 1;
      }
      if (this.choose_table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.choose_table.length * this.choose_table.page;
      if (valEnd > this.choose_table.total) {
        valEnd = this.choose_table.total;
      }
      if (this.choose_table.totalSearch < this.choose_table.total) {
        valEnd = this.choose_table.totalSearch;
      }

      this.choose_table.start = valStart;
      this.choose_table.end = valEnd;
    },
    async validateAdd(record) {
      // check if record already exist
      let exist = this.table_parent.data.find((item) => item.id === record.id);

      if (exist) {
        this.$vs.notify({
          title: "Failed",
          text: "Item already exist",
          color: "danger",
          position: "top-center",
        });
        return;
      }

      // qty is required
      if (!record.qty || record.qty == 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Qty is required",
          color: "danger",
          position: "top-center",
        });
        return false;
      }

      return true;
    },
    async handleAdd(record) {
      // check validation
      let valid = await this.validateAdd(record);
      if (!valid) {
        return;
      }

      // append to selected table
      let formated = {
        id: record.id,
        item_name: record.item_name,
        unit_name: record.unit_name,
        qty: record.qty,
        expired_date: record.expired_date,
        batch: record.batch,
        serial: record.serial,
        sku_code: record.sku_code,
      };
      this.table_parent.data.push(formated);

      // notify
      this.$vs.notify({
        title: "Success",
        text: "Item added",
        color: "success",
        position: "top-center",
      });
    },
    handleSearch(searching) {
      // strin must be at least 3 character
      if (searching.length < 3) {
        return;
      } else if (searching.length == 0) {
        this.reloadItemUnitTable();
      }

      this.choose_table.search = searching;
      this.reloadItemUnitTable();
    },
    handleChangePage(page) {
      this.choose_table.page = page;
      this.reloadItemUnitTable();
    },
    handleChangelength(val) {
      this.choose_table.length = val == "All" ? this.choose_table.total : val;
      this.choose_table.page = 1;
      this.reloadItemUnitTable();
    },
    tableDefaultState() {
      return {
        data: this.choose_table_data,
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    selectedTableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    async handleSubmit() {
      let datas = await this.selected_table.data.map((item) => {
        return {
          item_unit_id: item.id,
          quantity: parseInt(item.qty),
          expired_date: item.expired_date,
          batch: item.batch,
          serial: item.serial,
        };
      });

      let params = {
        stock_customers: [
          {
            company_id: this.filter_data.company_id,
            customer_id: this.filter_data.customer_id,
            details: datas,
          },
        ],
      };

      let results = await this.$http.post(
        "api/sfa/v1/adjustment-stock-item",
        params
      );

      if (results.code == 200) {
        this.$vs.notify({
          title: "Success",
          text: "Data has been saved",
          color: "success",
          position: "top-center",
        });
      } else {
        this.$vs.notify({
          title: "Failed",
          text: results.message,
          color: "danger",
          position: "top-center",
        });
      }

      this.handleClose();
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    async reloadItemUnitTable() {
      this.$vs.loading();
      let params = {
        page: this.choose_table.page,
        length: this.choose_table.length,
        search: this.choose_table.search,
        order: this.choose_table.order,
        ...this.filter_data,
      };

      let results = await this.$http.get(
        "api/sfa/v1/adjustment-stock-item/public",
        { params: params }
      );

      if (results.code == 200) {
        this.choose_table.total = results.data.total_record;
        this.choose_table.totalPage = results.data.total_page;
        this.choose_table.totalSearch = results.data.total_record_search;
        this.choose_table.length = results.data.total_record_per_page;
        this.choose_table.data = results.data.records;
        this.setStartEnd();
        this.$vs.loading.close();
      }

      this.$vs.loading.close();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
